.langswitch {
  position:relative;
  .tabs {
    position:absolute;
    z-index:1;
    top:3px;
    right:5px;
    display: flex;
    align-items: flex-end;
    .lang-btn {
      margin-left:5px;
      text-transform: uppercase;
      font-size: 0.8em;
      font-weight:bold;
      opacity:0.2;
      transition:opacity 0.5s;
      cursor:pointer;
      &.active {
        opacity:1;
      }
    };
  }
}
