@import "./variables.scss";
.topbar {
  color:#aaa;
  a {
      color:#ddd;
  }
  .MuiToolbar-root {
    padding:0 8px;
  }
  .topbar-item a {
    text-decoration: none;
    margin:0 5px;
    &.active {
      color:$mainColor;
    }
  }
  .topbar-item {
    margin-right:5px;
  }
  .publish {
    text-align:right;
    >* {
      margin:0 20px;
      color:#FFF;
      cursor: pointer;
    }
  }
  .topbar-logo {
    background-image: url('../../assets/logo.png');
    height:40px;
    width:40px;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    margin-right: 5px;
    display:inline-block;
  }
  .topbar-user {
    max-width:100px;
    line-height:1;
  }
}
.xs .topbar .topbar-logo {
  width:40px;
}

.topbar-drawer {
  a {
    text-decoration: none;
    color: inherit;
    &.active {
      color:$mainColor;
    }
  }
  .MuiDrawer-paper {
    min-width:50%;
  }
  .topbar-drawer-header {
    padding:15px;
  }
}
.xs {
  .topbar {
    .topbar-logo {
      background-image: url('../../assets/logo.png');
    }
  }
}
