.items {
  .item-card {
    position:relative;
    .done {
      position:absolute;
      bottom:5px;
      right:5px;
      font-size:10px;
      font-weight:500;
      color:#888;
      &>span {
        margin-left:5px;
      }
    }
  }
}
