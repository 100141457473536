.item {
  .preview {
    position:relative;
    width:100%;
    img {
      background-color:#888;
      max-width:100%;
      max-height:250px;
      &.shadow {
        box-shadow: 2px 2px 10px #0008;
      }
    }
  }
}
