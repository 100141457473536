.input-upload {
  .upload-drop-zone {
    border: dashed 2px #888;
    border-radius: 8px;
    padding:0 15px;
    color: #888;
    &.active {
      border: dashed 2px #000;
      color: #000;
    }
  }
  .preview-container {
    position:relative;
    margin-top:15px;
    width:100%;
    .preview {
      position:relative;
      width:100%;
      img {
        background-color:#888;
        max-width:100%;
        max-height: 200px;
        &.shadow {
          box-shadow: 2px 2px 10px #0008;
        }
      }
    }
  }
  .son-preview {
    margin-top:15px;
    border-radius: 8px;
    width:100%;
  }
  .name {
    margin:15px 0;
    &>span {
      user-select: all;
    }
  }
  .input-upload-name {
    text-align:left;
  }
}
